import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/introduction-to-hapi",
  "date": "2015-10-07",
  "title": "INTRODUCTION TO HAPI",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Created by Eran Hammer, hapi.js is an open source framework built on top of Node.js for building web applications and services by Wallmart. In this tutorial, we will go through the advantages of hapi and build a simple application using hapi as the server."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why hapi.js?`}</h2>
    <h3>{`1. Tested at Scale`}</h3>
    <p>{`Hapi.js is robust, stable and reliable. It was battle-tested during Walmart Black Friday without any problems. Major companies like `}<inlineCode parentName="p">{`Yahoo`}</inlineCode>{`, `}<inlineCode parentName="p">{`Disney`}</inlineCode>{`, `}<inlineCode parentName="p">{`Paypal`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Zappos`}</inlineCode>{` use hapi.js for their APIs and web applications.`}</p>
    <h3>{`2. Security`}</h3>
    <p>{`Security is a key consideration behind hapi.js for building secure web applications.`}</p>
    <h3>{`3. Modularization`}</h3>
    <p>{`Hapi.js allows logical modularization of your application. It also has a rich plugin interface. Large applications can be easily distributed over multiple smaller modules.`}</p>
    <h2>{`Installing hapi.js`}</h2>
    <p>{`In order to use hapi.js, you have to make sure you have `}<inlineCode parentName="p">{`Node.js`}</inlineCode>{` installed on your machine.`}</p>
    <Message type="info" title="Installing Node.js" content="Download and install Node.js [here](https://nodejs.org/en/)." mdxType="Message" />
    <p>{`In command line, run the npm install command for installing hapi:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install hapi --save
`}</code></pre>
    <h2>{`Creating a Server`}</h2>
    <p>{`Now we have installed Node.js, as a starting point, we are going to set up the server.`}</p>
    <p>{`Create a file called `}<strong parentName="p">{`server.js`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var Hapi = require('hapi');

var server = new Hapi.Server();

server.connection({
  host: 'localhost',
  port: 3000
});

server.start(function() {
  console.log('Server running at:', server.info.uri);
});
`}</code></pre>
    <p>{`Line 03 - Here a server instance is created.
Line 05-08 - A connection is established by providing our server with a `}<inlineCode parentName="p">{`host`}</inlineCode>{` and a `}<inlineCode parentName="p">{`port`}</inlineCode>{` number.
Line 10 - We are starting the server.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...

server.route({
  path: '/',
  method: 'GET',
  handler: function (request, reply) {
    reply('Hello world!');
  }
});

...
`}</code></pre>
    <h2>{`Routing`}</h2>
    <h3>{`1.Path`}</h3>
    <p>{`The absolute path (Line 04) is used to match incoming requests (must begin with '/'). Incoming requests are compared to the configured paths based on the server router configuration option. The path can include `}<inlineCode parentName="p">{`named parameters`}</inlineCode>{` enclosed in {} which will be matched against literal values in the request as described in Path parameters.`}</p>
    <h3>{`2.Method`}</h3>
    <p>{`The HTTP method (Line 05). Typically one of 'GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'. Any HTTP method is allowed, except for 'HEAD'.`}</p>
    <h3>{`3.Handler`}</h3>
    <p>{`The handler for the route (Line 06). Can only include one handler per route.`}</p>
    <Message type="warn" title="" content="HTTP method `HEAD` is not supported in the server route." mdxType="Message" />
    <p>{`Now you can start the server by running following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`node server.js
`}</code></pre>
    <h2>{`Plugins`}</h2>
    <p>{`Plugins allow you to build out your application components as self-contained modules.`}</p>
    <p>{`They also manipulate requests and other events, hook into server events, etc.`}</p>
    <p>{`Plugins are like middleware.`}</p>
    <p>{`We can modify each request at the extension points, using `}<strong parentName="p">{`server.ext()`}</strong>{`. Let's expand on our example code where we want to set a new request path values on all the incoming requests:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`...

server.route({
  path: '/test',
  method: 'GET',
  handler: function (request, reply) {
    reply('This is a test!');
  }
});

server.ext('onRequest', function (request, reply) {
    // Change all requests to '/test'
    request.setUrl('/test');
    return reply.continue();
});
`}</code></pre>
    <p>{`The Github source for this article can be found in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/hapi-intro"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      